const commonClasses = [
  "py-4",
  "px-16",
  "font-mono",
  "inline-block",
  "text-lg",
  "w-full",
  "md:w-auto",
  "transition-all",
  "duration-300",
  "ease-in-out",
  "text-center",
];

const primaryClasses = [
  ...commonClasses,
  "bg-white",
  "shadow-md",
  "border-0",
  "hover: border-4",
  "hover: border-white",
  "hover:text-white",
  "hover:bg-transparent",
];

const secondaryClasses = [
  ...commonClasses,
  "bg-black",
  "text-white",
  "hover: border-4",
  "hover: border-black",
  "hover:text-black",
  "hover:bg-transparent",
];

const secondaryDisabledClasses = [
  ...commonClasses,
  "bg-distinct-grey-concrete",
  "text-black",
  "cursor-not-allowed",
];

const outlineClasses = [
  ...commonClasses,
  "bg-transparent",
  "border-white",
  "text-white",
  "border-4",
  "transition-all",
  "hover:text-black",
  "hover:bg-white",
];

const outlineSmallClasses = [
  "border-2",
  "inline-block",
  "border-white",
  "font-mono",
  "px-10",
  "py-3",
  "text-md",
  "text-white",
];

const classes = {
  primary: primaryClasses,
  secondary: secondaryClasses,
  outlined: outlineClasses,
  outlinedSmall: outlineSmallClasses,
  secondaryDisabled: secondaryDisabledClasses,
};

export default classes;
