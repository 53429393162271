import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "gatsby";

import ChildrenType from "../../types/children";
import classes from "./classes";

const Button = ({
  children,
  externalLink,
  link,
  onClick,
  buttonType,
  variant,
  disabled,
}) => {
  if (link) {
    return (
      <Link to={link} className={classNames(classes[variant])}>
        {children}
      </Link>
    );
  }

  if (externalLink) {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={externalLink}
        className={classNames(classes[variant])}
      >
        {children}
      </a>
    );
  }

  return (
    <button
      onClick={onClick}
      type={buttonType}
      disabled={disabled}
      className={classNames(classes[variant])}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  link: PropTypes.string,
  onClick: PropTypes.func,
  buttonType: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  externalLink: PropTypes.string,
  ...ChildrenType,
};

Button.defaultProps = {
  link: null,
  onClick: null,
  buttonType: "submit",
  variant: "primary",
  disabled: false,
  externalLink: null,
};

export default Button;
