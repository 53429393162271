import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ChildrenType from "../../types/children";

const Heading = ({ children, className, variant, id }) => {
  const Type = variant;
  return (
    <Type
      id={id}
      className={classNames(className, ["font-gobold", "tracking-tight", "uppercase"])}
    >
      {children}
    </Type>
  );
};

Heading.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  id: PropTypes.string,
  ...ChildrenType,
};

Heading.defaultProps = {
  className: null,
  variant: "h1",
  id: null,
};

export default Heading;
