/**
 * React Children Prop Type
 */
import PropTypes from "prop-types";

const ChildrenType = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
    .isRequired,
};

export default ChildrenType;
